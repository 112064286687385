import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { race, call, take, put, all, fork, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { extractGetVariables, getAuthUrl } from '../utils';
import { handleApiErrors } from '../lib/api-errors';
import { push } from 'react-router-redux';
import setUser from './user';
import { getSelectedMenu } from '../selectors/global.selector';
import { requestHandler } from './fetcher.saga';
import { LOGOUT } from '../constants/login';
import { browserHistory } from 'react-router';
import { REQUEST_REJECT } from '../constants/request';
import { KEY_FIRST_LOGIN } from '../constants/variable';
import { UPDATE_USER, UPDATE_USER_KEY } from '../constants/user';

import { ADD_NOTIFICATION, TAGS } from '../constants/notification';
import { I18n } from 'react-redux-i18n';
import { RECEIVE_TUTO, START_TUTO } from '../constants/tutorial';
import { sendUnloadBeacon } from '../utils';
import {
	RECEIVE_DAY_CHANGES,
	RECEIVE_DAY_CHANGES_ALL,
	RECEIVE_HAS_DAY_CHANGES,
	RECEIVE_HAS_DAY_CHANGES_ALL,
	RECEIVE_PROVIDER_CHANGES,
} from '../constants/dayChanges';
import { RECEIVE_ZONE } from '../constants/zone';
import { RECEIVE_MENU } from '../constants/menu';
import { RECEIVE_CHILDREN } from '../constants/children';

// Side effects Services
const getRefreshToken = () => window.localStorage.refresh_token;
const getAccessToken = () => window.localStorage.access_token;
const decodeAccessToken = access_token => jwtDecode(access_token);
const getTokenInUrl = () => extractGetVariables(document.location.search);

// const setRefreshToken = token => {
//   window.localStorage.setItem("access_token", token);
// };

// const setAccessToken = token => {
//   window.localStorage.setItem("access_token", token);
// };

// const removeRefreshToken = () => {
//   window.localStorage.removeItem("refresh_token");
// };

// const removeAccessToken = () => {
//   window.localStorage.removeItem("access_token");
// };

const signout = params => {
	sendUnloadBeacon();

	window.sessionStorage.removeItem('master_mono');
	window.localStorage.removeItem('access_token');
	window.localStorage.removeItem('refresh_token');
	document.location.replace(getAuthUrl() + 'logout');
};

function* authService(token) {
	const response = yield call(
		requestHandler,
		'accessToken',
		'GET',
		false,
		null,
		'',
		{
			token,
		}
	);
	if (!response || !response.result) {
		return null;
	}

	const result = response.result;
	if (result.status === 401 && result.code) {
		return result;
	}

	if (result.status === 403) {
		return { ...result, error: 'RULES_STATUS_DISABLED' };
	}

	return result.result;
}

const setAuthToken = token => {
	window.localStorage.access_token = token;
};

function* authorize(token) {
	yield put({ type: 'LOGIN_REQUESTING' });
	const { response } = yield race({
		response: call(authService, token),
		signout: take(LOGOUT),
	});
	// server responded (with Success) before user signed out
	if (response && response.accessToken) {
		yield call(setAuthToken, response.accessToken); // save to local storage
		yield put({ type: 'LOGIN_SUCCESS' });
		return response.accessToken;
	} else {
		// user signed out before server response OR server responded first but with error
		if (response) {
			yield put({ type: 'LOGIN_ERROR', error: response });
		} else {
			yield call(signout, 'User signed out');
		}
		return false;
	}
}

export function* getMenuSelected() {
	const store = yield select();
	const id_menu = getSelectedMenu(store.entity.variableServer);
	return id_menu;
}

export function* fetchAll() {
	const firstRequest = yield call(
		requestHandler,
		'patchMenu',
		'POST',
		true,
		{ date: moment().format() },
		'/firstGet'
	);

	if (firstRequest.result === false) {
		window.localStorage.setItem('CAN_ACCESS', false);
		yield put({ type: 'RESET_LOADING' });
		yield put({ type: REQUEST_REJECT });
		return;
	} else {
		window.localStorage.setItem('CAN_ACCESS', true);
	}
	const variableServer = yield call(
		requestHandler,
		'variableServer',
		'GET',
		true,
		null,
		'',
		{
			uuid: window.sessionStorage.getItem('guid'),
		}
	);

	let results;
	let resultMenu;
	let resultChildren;
	let resultZone;
	let resultCanAccess;

	if (
		variableServer &&
		variableServer.result &&
		variableServer.result.result &&
		variableServer.result.result.length > 0
	) {
		let variableIdMenu = variableServer.result.result.find(
			variable => variable.key_ === 'selected_menu'
		);

		if (variableIdMenu) {
			let idMenu = variableIdMenu.value;

			[results, resultMenu, resultChildren, resultZone, resultCanAccess] =
				yield all([
					call(requestHandler, 'all', 'GET', true, null, '', {
						id_menu: idMenu,
					}),
					call(requestHandler, 'menu', 'GET', true, null, '', {
						id_menu: idMenu,
					}),
					call(requestHandler, 'patchMenu', 'GET', true, null, '/children', {
						id_menu: idMenu,
					}),
					call(requestHandler, 'zone', 'GET', true, null, '', {
						guid: window.sessionStorage.getItem('guid'),
					}),
					call(requestHandler, 'menu', 'GET', true, null, '/canAccess', {
						id_menu: idMenu,
					}),
				]);
		}
	}

	if (variableServer.result === false) {
		window.localStorage.setItem('CAN_ACCESS', false);
		yield put({ type: 'RESET_LOADING' });
		if (window.location.pathname != '/deployRecap') {
			browserHistory.push('/menu');
			yield put({ type: REQUEST_REJECT });
		}
	} else {
		if (
			variableServer.result.result &&
			variableServer.result.result.multiSession
		) {
			window.sessionStorage.setItem('ALREADY_IN_USE', true);
			yield put({
				type: 'RECEIVE_VARIABLE_SERVER',
				response: variableServer.result.result,
			});

			yield put({ type: 'RESET_LOADING' });
			yield put({ type: 'RECEIVED_ALL' });
		} else {
			window.sessionStorage.removeItem('ALREADY_IN_USE');
			if (variableServer.result.isModificationPossible != false) {
				yield put({
					type: 'RECEIVE_VARIABLE_SERVER',
					response: variableServer.result,
				});
				yield put({
					type: UPDATE_USER,
					user: {
						master: variableServer.result.isMaster,
					},
				});
				window.localStorage.setItem('CAN_ACCESS', true);

				if (variableServer.result.menuEmpty) {
					yield put({
						type: ADD_NOTIFICATION,
						notification: {
							tags: [TAGS.WARNING],
							text: I18n.t('menu.createMenu'),
							date: moment().format('DD/MM/YYYY HH:mm'),
							viewed: false,
						},
					});
					yield put({ type: 'RECEIVED_ALL' });
					browserHistory.push('/menu');
				} else {
					const idMenu = yield call(getMenuSelected);

					const canAccessMenu =
						resultCanAccess &&
						resultCanAccess.result &&
						resultCanAccess.result.result &&
						!!resultCanAccess.result.result.access; //menu accessible and menuS not empty
					if (canAccessMenu) {
						if (!results) {
							let calls = [
								call(requestHandler, 'all', 'GET', true, null, '', {
									id_menu: idMenu,
								}),
							];

							results = yield all(calls);
						}

						const isMasterMono = resultCanAccess.result.masterMono;

						let resultMenuMasterMono = false;
						if (isMasterMono) {
							window.sessionStorage.setItem('master_mono', true);
							resultMenuMasterMono = yield call(
								requestHandler,
								'menuMasterMono',
								'GET',
								true,
								null,
								'',
								{ guid: window.sessionStorage.getItem('guid') }
							);
							results.result.result = {
								...results.result.result,
								[resultMenuMasterMono.entity]:
									resultMenuMasterMono &&
									resultMenuMasterMono.result &&
									resultMenuMasterMono.result
										? resultMenuMasterMono.result.result
										: [],
							};
						}

						if (results.result.result.access) {
							let access_mode = 'default';
							if (
								(window.sessionStorage.getItem('master_mono_child') ||
									variableServer.result.isChild) &&
								!isMasterMono
							) {
								access_mode = 'child';
							} else if (
								window.sessionStorage.getItem('id_zone') &&
								!isMasterMono
							) {
								access_mode = 'zone';
							} else {
								access_mode = 'default';
							}
							yield put({
								type: 'RECEIVE_ACCESS',
								data: results.result.result.access,
								mode: access_mode,
							});
						}

						yield put({
							type: RECEIVE_MENU,
							response: resultMenu.result,
						});
						yield put({
							type: RECEIVE_CHILDREN,
							response: resultChildren.result,
						});
						yield put({
							type: RECEIVE_ZONE,
							response: resultZone.result,
						});

						const entityToAction = {
							category: 'RECEIVE_CATEGORY',
							supplementCategory: 'RECEIVE_SUPPLEMENT_CATEGORY',
							product: 'RECEIVE_PRODUCT',
							productRemoved: 'RECEIVE_PRODUCT',
							productMenu: 'RECEIVE_PRODUCT_MENU',
							color: 'RECEIVE_COLOR',
							menu: 'RECEIVE_MENU',
							menuMasterMono: 'RECEIVE_MENU_MASTER_MONO',
							tax: 'RECEIVE_TAX',
							supplement: 'RECEIVE_SUPPLEMENT',
							productType: 'RECEIVE_PRODUCT_TYPE',
							placeSend: 'RECEIVE_PLACE_SEND',
							synchro: 'RECEIVE_SYNCHRO',
							floor: 'RECEIVE_FLOOR',
							variable: 'RECEIVE_VARIABLE',
							priceCategory: 'RECEIVE_PRICE_CATEGORY',
							retailPriceType: 'RECEIVE_RETAIL_PRICE_TYPE',
							zone: RECEIVE_ZONE,
							menu: RECEIVE_MENU,
							synchroDayChanges: RECEIVE_DAY_CHANGES_ALL,
							synchroHasDayChanges: RECEIVE_HAS_DAY_CHANGES_ALL,
							synchroProvider: RECEIVE_PROVIDER_CHANGES,
							patchMenuChilren: RECEIVE_CHILDREN,
							patchMenuPeripherals: UPDATE_USER_KEY,
							patchMenuSwiftVersion: UPDATE_USER_KEY,
						};

						if (results) {
							for (let key in results.result.result) {
								if (entityToAction[key]) {
									yield put({
										type: entityToAction[key],
										response: {
											result: results.result.result[key],
										},
									});
								}
							}
						}

						// handle master mono edit menu
						if (
							isMasterMono &&
							window.location.pathname != '/menu' &&
							!window.sessionStorage.master_mono_child
						) {
							yield put({ type: 'RECEIVED_ALL' });
							browserHistory.push('/menu');
							return;
						}

						yield put({ type: 'RECEIVED_ALL' });

						// LOG FISRT LOGIN
						const internal =
							window.location.hostname.includes('dev') ||
							window.location.hostname.includes('localhost');
						const firstLogin = variableServer.result.result.find(
							variable => variable.key_ === KEY_FIRST_LOGIN
						);
						if (!firstLogin || (firstLogin && firstLogin.value == 0)) {
							yield call(
								requestHandler,
								'variableServer',
								'POST',
								true,
								{ internal },
								'/first-login'
							);
							yield call(
								requestHandler,
								'auth',
								'GET',
								true,
								null,
								'/creation',
								{ refreshToken: window.localStorage.refresh_token }
							);
						}
					} else {
						yield put({ type: 'RECEIVED_ALL' });
						browserHistory.push('/menu');
					}
				}
			}
		}
	}
}

export default function* authFlowSaga() {
	let accessToken = yield call(getAccessToken); // retreive from local storage
	let refreshToken = yield call(getRefreshToken); // retreive from local storage
	const getVar = yield call(getTokenInUrl);

	// if we do not have a refresh token we redirect to the auth server
	if (!getVar.refreshToken && !refreshToken) {
		const withScope = true;
		document.location.replace(getAuthUrl(withScope));
		return;
	}

	// if we have a token in URL and it is more recent than the one in the localStorage
	if (
		getVar.refreshToken &&
		(!refreshToken || compareMostRecentToken(getVar.refreshToken, refreshToken))
	) {
		// we store this token in the localStorage
		localStorage.setItem('refresh_token', getVar.refreshToken);
		refreshToken = getVar.refreshToken;
	}

	while (true) {
		if (!accessToken) {
			accessToken = yield call(authorize, refreshToken);
		}

		yield fork(fetchAll);

		let userSignedOut;
		while (!userSignedOut) {
			const decoded = yield call(decodeAccessToken, accessToken);
			yield call(setUser, decoded, accessToken);
			const token_expires_timestamp = decoded.exp;
			const CurrentDate = moment().unix();
			const token_expires_wait_before_update =
				token_expires_timestamp - CurrentDate;
			const token_expires_wait_before_update_millisecond =
				token_expires_wait_before_update * 1000;
			const { expired } = yield race({
				expired: delay(token_expires_wait_before_update_millisecond),
				signout: take(LOGOUT),
			});
			// token expired first
			if (expired) {
				if (window.localStorage.networkStatus === 'offline') {
					yield take('SET_NETWORK_STATUS');
				}
				accessToken = yield call(authorize, refreshToken);
				// authorization failed, either by the server or the user signout
				if (!accessToken) {
					userSignedOut = true; // breaks the loop
					yield call(signout);
				}
			} else {
				// user signed out before token expiration
				userSignedOut = true; // breaks the loop
				yield call(signout);
			}
		}
	}
}

/**
 * @param {string} token_url JWT token from GET params
 * @param {string} local_token JWT token from local storage
 * @returns {boolean} true if GET token is more recent than localStorage token
 */
export function compareMostRecentToken(token_url, local_token) {
	const urlPayload = jwtDecode(token_url);
	const localPayload = jwtDecode(local_token);

	return urlPayload.iat > localPayload.iat;
}
