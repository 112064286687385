import {
	RECEIVE_DAY_CHANGES,
	RECEIVE_HAS_DAY_CHANGES,
	RECEIVE_HAS_DAY_CHANGES_ALL,
	RECEIVE_PROVIDER_CHANGES,
	RECEIVE_DAY_CHANGES_ALL,
} from '../constants/dayChanges';

const initialState = { list: {}, hasDayChanges: false, providerChanges: [] };

const reducer = function dayChangesReducer(state = initialState, action) {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_DAY_CHANGES:
			if (action.data) {
				newState.list = action.data;
			}
			return newState;
		case RECEIVE_DAY_CHANGES_ALL:
			if (action.response && action.response.result) {
				newState.list = action.response.result || {};
			}
			return newState;

		case RECEIVE_HAS_DAY_CHANGES:
			newState.hasDayChanges = action.data || false;
			return newState;

		case RECEIVE_HAS_DAY_CHANGES_ALL:
			newState.hasDayChanges = action.response.result || false;
			return newState;

		case RECEIVE_PROVIDER_CHANGES:
			newState.providerChanges = action.response.result || [];
			return newState;

		default:
			return state;
	}
};

export default reducer;
