const entities = [
	'category',
	'product',
	'productMenu',
	'categorySupplement',
	'supplement',
	'priceCategory',
	'taxRate',
	'exclusion',
];

let ACCESSES = entities.reduce((acc, entity) => {
	acc[entity] = {
		zone: { create: false, read: true, update: true, delete: false },
		child: { create: false, read: true, update: true, delete: false },
		default: { create: true, read: true, update: true, delete: true },
	};
	return acc;
}, {});
ACCESSES.taxRate.child.create = true;
ACCESSES.priceCategory.zone.read = false;

let initialState = {
	accesses: ACCESSES,
	current: getAllAccess(ACCESSES, 'default'),
	mode: 'default',
};

function getAllAccess(accesses, mode) {
	return entities.reduce((acc, entity) => {
		if (
			accesses.hasOwnProperty(entity) && //FIXME:
			accesses[entity].hasOwnProperty(mode)
		) {
			acc[entity] = accesses[entity][mode];
		} else {
			acc[entity] = {
				create: false,
				read: false,
				update: false,
				delete: false,
			};
		}
		return acc;
	}, {});
}

const reducer = function accessReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case 'RECEIVE_ACCESS':
			return {
				...newState,
				accesses: action.data,
				current: getAllAccess(action.data, action.mode),
			};
		case 'SET_ACCESS_MODE':
			if (action.mode) {
				return {
					...newState,
					mode: action.mode,
					current: getAllAccess(newState.accesses, action.mode),
				};
			}
			return newState;
		default:
			return newState;
	}
};

export default reducer;
