import {
	RECEIVE_PRODUCT,
	SWITCH_SORT_PRODUCT,
	SEND_PRODUCT,
	PRODUCT_SENT,
	REMOVE_PRODUCT,
	UPDATE_PRODUCT,
	CREATE_PRODUCT,
	DELETE_PRODUCT,
	REWEIGHT_PRODUCT,
	UPDATE_PRODUCT_WITHOUT_FETCH,
} from '../constants/product';
import _remove from 'lodash/remove';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import findIndex from 'lodash/findIndex';

const initialState = {
	products: [],
	queued_product: [],
	sort: {
		id: 0,
		name: 'product.sort.category_asc',
		value: ['list_categories'],
		direction: 'asc',
	},
	sortList: [],
	product_action_past: [],
	product_action_future: [],
	productsRemoved: [],
};

const reducer = function productReducer(state = initialState, action) {
	const newState = Object.assign({}, state);
	switch (action.type) {
		case UPDATE_PRODUCT:
		case REWEIGHT_PRODUCT:
		case CREATE_PRODUCT:
		case RECEIVE_PRODUCT:
			// if (action.response.result.length === 0) {
			//   return state;
			// }
			if (action.response.result && action.response.result.removed) {
				const removed_products = action.response.result.result;
				return {
					...newState,
					productsRemoved: removed_products,
					action: action.action,
				};
			} else {
				const received_products = action.response.result;
				return {
					...newState,
					products: received_products,
					action: action.action,
				};
			}

		case SWITCH_SORT_PRODUCT:
			return {
				...newState,
				sort: { ...action.sort },
			};
		case 'INIT_PRODUCT_SORT':
			return {
				...newState,
				sortList: [...action.sorts],
				sort: { ...action.sorts[0] },
			};
		case UPDATE_PRODUCT_WITHOUT_FETCH:
			const newProducts = [...state.products];
			if (!action.response.result) {
				return newState;
			}
			const received_products = action.response.result;
			const { ids, data } = received_products;
			if (!ids || ids.length <= 0) {
				return newState;
			}
			_forEach(ids, (id, key) => {
				const elementToUpdateIndex = findIndex(newProducts, p => p.id === id);
				const elementToUpdate = newProducts[elementToUpdateIndex];
				const updatedElement = { ...elementToUpdate };
				const newValues = _find(data, d => {
					return d.id === id;
				});
				_forEach(newValues.newData, (value, key) => {
					updatedElement[key] = value;
				});
				newProducts[elementToUpdateIndex] = updatedElement;
			});
			//console.log(updatedElement);
			/* _forEach(ids, (id, key) => {
      const elementToUpdateIndex = findIndex(
        newProducts,
        p => p.id === id
      );
      const elementToUpdate = newProducts[elementToUpdateIndex];
      const updatedElement = { ...elementToUpdate };
      _forEach(action.newValues[key], (value, key) => {
        updatedElement[key] = value;
      });
      newProducts[elementToUpdateIndex] = updatedElement;
    }); */
			return { ...newState, products: newProducts };
		//return { ...newState };
		case CREATE_PRODUCT:
			newState.products.push(...action.newProducts);

			return newState;

			break;
		case SEND_PRODUCT:
			return state;
		case PRODUCT_SENT:
			return state;
		case REMOVE_PRODUCT:
			return state;
		/* case CREATE_PRODUCT :
      newState.products.push(...action.newProducts);
      newState.action = action.action;

      return newState; */

		case DELETE_PRODUCT:
			const deletedIds = action.ids;

			const updatedProducts = _remove(newState.products, p => {
				return deletedIds.indexOf(p.id) === -1;
			});

			newState.products = updatedProducts;
			newState.action = action.action;

			return newState;

		case 'ADD_PRODUCT_ACTION_PAST':
			const productPastAction = action.action;
			const pastActions = [...newState.product_action_past];
			pastActions.push(productPastAction);
			newState.product_action_past = pastActions;

			return newState;
		case 'POP_PRODUCT_ACTION_PAST':
			const pastActions2 = [...newState.product_action_past];
			pastActions2.pop();
			newState.product_action_past = pastActions2;

			return newState;
		case 'POP_PRODUCT_ACTION_FUTURE':
			const futureActions2 = [...newState.product_action_future];
			futureActions2.pop();
			newState.product_action_future = futureActions2;

			return newState;
		case 'ADD_PRODUCT_ACTION_FUTURE':
			const productFutureAction = action.action;
			const futureActions = [...newState.product_action_future];
			futureActions.push(productFutureAction);
			newState.product_action_future = futureActions;

			return newState;
		case 'RESTORE_PRODUCT':
			newState.productsRemoved = newState.productsRemoved.filter(
				p => p.id != action.id_product
			);

			return newState;

		default:
			return state;
	}
};

export default reducer;
